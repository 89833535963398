import React, { ReactElement, useContext } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout, { isBrowser, UserContext, withLayout } from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import styles from "./courses.module.css"

import firebase from 'firebase';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'

import Cookies from 'universal-cookie';


import { navigate } from '@reach/router';
const cookies = new Cookies();

const Cart: React.FC = (props: PageProps<GatsbyTypes.CoursesQuery>) => {
  console.log(props.data.allContentfulCourse)
  let cart = cookies.get('cart');

  if (cart == undefined || cart == null || Object.keys(cart).every((k) => !cart[k]) ) {
    return (
      <div>
      <h1 className={styles.header}>Cart</h1>
        Your cart is empty!
        Go to the
        <Link to={`/courses`}> <Button>Courses</Button></Link> page to add some course to your cart!
      </div>
    )

  }

  return (
    <>
      <h1 className={styles.header}>Cart</h1>

      {props.data.allContentfulCourse.edges.map(course => {
        if (cart[course.node.slug]) {
          return (<CartCourseCard key={course.node.slug} course={course.node} />)
        }
      }

      )}

    </>
  );
}

export default withLayout(Cart);





interface CourseCardProps {
  course: GatsbyTypes.ContentfulCourse,
}

export function CartCourseCard({ course }: CourseCardProps): ReactElement {
  return (
    <div key={course.slug}>
      <Card className={styles.root}>
        <CardActionArea>
          <CardMedia
            className={styles.media}
            image={course.image?.fluid?.src}
            title="Course Background Image"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {course.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {course.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={() => {
            buyCourse(course.slug)
          }}>
            Buy this course
          </Button>

          <Button size="small" color="secondary" onClick={() => {
            removeFromCart(course.slug, true)
          }}>
            Remove from cart
          </Button>
          {/* <Button size="small" color="primary">
                Learn More
              </Button> */}

        </CardActions>
      </Card>
    </div>
  )
  async function buyCourse(courseSlug) {
    let email = firebase.auth().currentUser?.email
    fetch(`/.netlify/functions/buyCourse?slug=${courseSlug}&email=${email}`).then(res => { console.log(res); return (res.json()) }).then(res => {
      console.log(res)
      if (res.status == 200 && res.url) {
        removeFromCart(courseSlug, false)
        navigate(res.url);
      }
      else {
        throw "Failed to fetch from backend server."
      }
    }).catch(err => {
      // console.log(err);
      alert("Something went wrong... Please contact us at help@leanqualityacademy.com if you continue experiencing this issue. Error:" + err.toString());
      throw err
    })

  }
}



export const pageQuery = graphql`
query Cart  {
  allContentfulCourse {
    edges{
      node{
        slug
        title
        image{
          fluid(maxWidth: 1600) {
              src
          }
        }
      }
    }
  }
}
`

function removeFromCart(courseSlug, refreshCart = false) {
  let cart = cookies.get('cart');
  if (cart[courseSlug]) {
    cart[courseSlug] = false;
  }
  cookies.set('cart', cart, { path: '/' })
  if(refreshCart){
    location.reload()
  }
}